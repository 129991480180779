import { fetchAuthSession } from 'aws-amplify/auth';

import { configureAmplify } from '@pumpkincare/portal-config';
import {
  AUTH_VET_LS_KEY,
  ERROR,
  LOADING,
  setIsLoggedIn,
} from '@pumpkincare/portal-shared';
import { buildAuthHeaders } from '@pumpkincare/shared';

import { apiGet, setAppAuth } from '../state';

export default function fetchUser() {
  return dispatch => {
    configureAmplify();
    dispatch(setAppAuth({ status: LOADING, error: null }));

    return fetchAuthSession()
      .catch(error => {
        dispatch(setAppAuth({ error, isLoggedIn: false, status: ERROR }, ERROR));
        throw error;
      })
      .then(authSession => {
        dispatch(setAppAuth({ isLoggedIn: true }));
        setIsLoggedIn(AUTH_VET_LS_KEY, true);

        const headers = buildAuthHeaders(authSession);

        return dispatch(
          apiGet({
            url: '/users/self',
            options: {
              headers,
              onError: setAppAuth,
            },
          })
        ).then(data =>
          dispatch(
            apiGet({
              url: `/users/${data.id}`,
              options: { headers, onError: setAppAuth, onSuccess: setAppAuth },
            })
          )
        );
      });
  };
}
